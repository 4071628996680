import dynamic from "next/dynamic";
import Layout from "../components/general/layout";
import groq from "groq";
import client from "../client";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Parallax } from "react-parallax";

import Image from "next/image";
import mobileWave from "../public/img/wave-mobile.png";
import urlFor from "../lib/sanity";

const Banner = dynamic(() => import("../components/page/banner"));
const CardBanner = dynamic(() => import("../components/page/cardBanner"));
const CardGrid = dynamic(() => import("../components/page/cardGrid"));
const CardGridWithBackground = dynamic(() =>
	import("../components/page/cardGridWithBackground")
);
const CardGridMosaic = dynamic(() =>
	import("../components/page/cardGridMosaic")
);
const CardsWithBackground = dynamic(() =>
	import("../components/page/cardsWithBackground")
);
const Chargers = dynamic(() => import("../components/page/chargers"));
const ContentCardWithBackground = dynamic(() =>
	import("../components/page/contentCardWithBackgroundImage")
);
const ContentsGallery = dynamic(() =>
	import("../components/page/contentsGallery")
);
const NewContentsGallery = dynamic(() =>
	import("../components/page/newContentsGallery")
);
const ContentWithImage = dynamic(() =>
	import("../components/page/contentWithImage")
);
const ContentWithImageGroup = dynamic(() =>
	import("../components/page/contentWithImageGroup")
);
const Cta = dynamic(() => import("../components/page/cta"));
const CtaCard = dynamic(() => import("../components/page/ctaCard"));
const CtaCardBackgroundImage = dynamic(() =>
	import("../components/page/ctaCardBackgroundImage")
);
const NewCtaCardBackgroundImage = dynamic(() =>
	import("../components/page/newCtaCardBackgroundImage")
);
const BannerCardWithBackground = dynamic(() =>
	import("../components/page/bannerCardWithBackground")
);
const ImageGallery = dynamic(() => import("../components/page/imageGallery"));
const LegalCopy = dynamic(() => import("../components/page/legalCopy"));
const CtaHelp = dynamic(() => import("../components/page/ctaHelp"));
const HomeTop = dynamic(() => import("../components/page/homeTop"));
const LocationFinder = dynamic(() =>
	import("../components/page/locationFinder")
);
const PackageBlock = dynamic(() => import("../components/page/packageBlock"));
const NewPackageBlock = dynamic(() =>
	import("../components/page/newPackageBlock")
);
const SimpleContent = dynamic(() => import("../components/page/simpleContent"));
const SimpleTitle = dynamic(() => import("../components/page/simpleTitle"));
const SimpleImage = dynamic(() => import("../components/page/simpleImage"));
const CtaWithoutTitle = dynamic(() =>
	import("../components/page/ctaWithoutTitle")
);
const SliderCards = dynamic(() => import("../components/page/sliderCards"));
const SliderGrid = dynamic(() => import("../components/page/sliderGrid"));
const Accessibility = dynamic(() => import("../components/page/accessibility"));
const ImageBanner = dynamic(() => import("../components/page/imageBanner"));
const VideoBanner = dynamic(() => import("../components/page/videoBanner"));
const IndustrialServiceRequestForm = dynamic(() =>
	import("../components/page/industrialServiceRequestForm")
);

// Render the page in React
export default function Page({ title, pageBuilder, seoSettings }) {
	const router = useRouter();
	const pageTitle =
		seoSettings && seoSettings.pageTitle ? seoSettings.pageTitle : title;
	const metaDescription =
		seoSettings && seoSettings.metaDescription
			? seoSettings.metaDescription
			: "";
	const ogImage =
		seoSettings && seoSettings.ogImage ? urlFor(seoSettings.ogImage).url() : "";

	let isHome = false;

	if (router.asPath === "/") {
		isHome = true;
	}

	const [parallaxDisabled, setParallaxDisabled] = useState(false);

	function handleParallax() {
		const { innerWidth: width, innerHeight: height } = window;

		if (width >= 1024) {
			setParallaxDisabled(false);
		} else {
			setParallaxDisabled(true);
		}
	}

	useEffect(() => {
		const body = document.querySelector("body");
		body.classList.add("bg-black");

		if (router.asPath === "/") {
			window.addEventListener("load", handleParallax);
		}
	});

	const pageCode = (
		<>
			{pageBuilder ? (
				<>
					{pageBuilder.map((component, k) => {
						switch (component._type) {
							case "banner":
								return <Banner data={component} key={component._key} />;
							case "card.banner":
								return <CardBanner data={component} key={component._key} />;
							case "card.grid":
								return <CardGrid data={component} key={component._key} />;
							case "card.grid.mosaic":
								return <CardGridMosaic data={component} key={component._key} />;
							case "card.grid.map":
								return (
									<CardsWithBackground data={component} key={component._key} />
								);
							case "card.grid.withBackground":
								return (
									<CardGridWithBackground
										data={component}
										key={component._key}
									/>
								);
							case "chargers":
								return <Chargers data={component} key={component._key} />;
							case "cta.contentCard.backgroundImage":
								return (
									<ContentCardWithBackground
										data={component}
										key={component._key}
									/>
								);
							case "contents.gallery":
								return (
									<ContentsGallery data={component} key={component._key} />
								);
							case "new.contents.gallery":
								return (
									<NewContentsGallery data={component} key={component._key} />
								);
							case "contentWithImage":
								return (
									<ContentWithImage data={component} key={component._key} />
								);
							case "contentWithImageGroup":
								return (
									<ContentWithImageGroup
										data={component}
										key={component._key}
									/>
								);
							case "cta":
								return <Cta data={component} key={component._key} />;
							case "cta.card":
								return <CtaCard data={component} key={component._key} />;
							case "cta.card.backgroundImage":
								return (
									<CtaCardBackgroundImage
										data={component}
										key={component._key}
									/>
								);
							case "new.cta.card.backgroundImage":
								return (
									<NewCtaCardBackgroundImage
										data={component}
										key={component._key}
									/>
								);
							case "banner.card":
								return (
									<BannerCardWithBackground
										data={component}
										key={component._key}
									/>
								);
							case "image.gallery":
								return <ImageGallery data={component} key={component._key} />;
							case "legal.copy":
								return <LegalCopy data={component} key={component._key} />;
							case "cta.help":
								return <CtaHelp data={component} key={component._key} />;
							case "home.top":
								return (
									<HomeTop
										data={component}
										lang={router.locale}
										key={component._key}
									/>
								);
							case "locationFinder":
								return <LocationFinder data={component} key={component._key} />;
							case "packageBlock":
								return <PackageBlock data={component} key={component._key} />;
							case "newPackageBlock":
								return (
									<NewPackageBlock data={component} key={component._key} />
								);
							case "simpleContent":
								return <SimpleContent data={component} key={component._key} />;
							case "simpleTitle":
								return <SimpleTitle data={component} key={component._key} />;
							case "simpleImage":
								return <SimpleImage data={component} key={component._key} />;
							case "cta.without.title":
								return (
									<CtaWithoutTitle data={component} key={component._key} />
								);
							case "slider.cards":
								return <SliderCards data={component} key={component._key} />;
							case "slider.grid":
								return <SliderGrid data={component} key={component._key} />;
							case "accessibility":
								return <Accessibility data={component} key={component._key} />;
							case "image.banner":
								return <ImageBanner data={component} key={component._key} />;
							case "video.banner":
								return <VideoBanner data={component} key={component._key} />;
							case "industrialServiceRequestForm":
								return (
									<IndustrialServiceRequestForm
										data={component}
										key={component._key}
									/>
								);
						}
					})}
				</>
			) : null}
		</>
	);

	return (
		<Layout
			pageTitle={pageTitle}
			metaDescription={metaDescription}
			ogImage={ogImage}
		>
			{isHome ? (
				<div className="relative">
					<div className="relative z-1">
						<Parallax
							bgImage="/img/wave.jpg"
							bgImageAlt=""
							strength={-5000}
							disabled={parallaxDisabled}
							bgClassName="hidden lg:block"
						>
							{pageCode}
						</Parallax>
					</div>

					<div className="lg:hidden w-full h-full absolute top-0 left-0 right-0 overflow-hidden">
						<Image src={mobileWave} alt="" objectFit="cover" />
					</div>
				</div>
			) : (
				<>{pageCode}</>
			)}
		</Layout>
	);
}

export async function getStaticPaths() {
	// Get all page slugs from Sanity
	const pages = await client.fetch(groq`*[_type == "page"] { language, slug }`);
	const paths = [];

	// Add page slugs to the list of paths in Next
	pages.map((page) => {
		if (page.slug.current !== "blog" && page.slug.current !== "blogue") {
			// paths.push({
			//     params: {
			//         slug: page.slug.current,
			//     },
			//     locale: page.language
			// });

			if (
				page.slug.current === "/fr" ||
				page.slug.current === "/fr/" ||
				page.slug.current === "//fr/"
			) {
				paths.push({
					params: {
						slug: "fr",
					},
					locale: page.language,
				});
			} else {
				paths.push({
					params: {
						slug: page.slug.current,
					},
					locale: page.language,
				});
			}
		}
	});

	return {
		paths,
		fallback: "blocking",
	};
}

// Query data for the current page from Sanity
// Types with links need to be specified to retrieve the slug from the reference
export const query = groq`*[_type == "page" && slug.current == $slug && language == $lang][0] {
    title,
    pageBuilder[] {
        ...,
        link {
            ...,
            internalLink-> { _type, slug },
            fileLink {
                asset-> { url }
            },
        },
        links[] {
            ...,
            internalLink-> { _type, slug },
            link {
                ...,
                internalLink-> { _type, slug },
                fileLink {
                    asset-> { url }
                },
            },
        },
        icons[] {
            ...,
            link {
                ...,
                internalLink-> { _type, slug },
                fileLink {
                    asset-> { url }
                },
            },
        },
        cards[] {
            ...,
            link {
                ...,
                internalLink-> { _type, slug },
                fileLink {
                    asset-> { url }
                },
            },
            file {
                ...,
                file {
                    ...,
                    asset->
                }
            },
        },
        chargers[] {
            ...,
            link {
                ...,
                internalLink-> { _type, slug },
                fileLink {
                    asset-> { url }
                },
            },
        },
        slides[] {
            ...,
            link {
                ...,
                internalLink-> { _type, slug },
                fileLink {
                    asset-> { url }
                },
            },
        },
        applicationLinks[] {
            ...,
            internalLink-> { _type, slug },
            fileLink {
                asset-> { url }
            },
        },
        categoryLinks[] {
            ...,
            internalLink-> { _type, slug },
            fileLink {
                asset-> { url }
            },
        },
        body[] {
            ...,
            markDefs[] {
                ...,
                _type == "internalLink" => {
                    "slug": @.reference->slug
                },
                _type == "button" => {
                    ...,
                    internalLink-> { slug }
                }
            }
        },
        contentWithImageComponents[] {
            ...,
            body[] {
                ...,
                markDefs[] {
                    ...,
                    _type == "internalLink" => {
                        "slug": @.reference->slug
                    },
                    _type == "button" => {
                        ...,
                        internalLink-> { slug }
                    },
                    internalLink-> { slug }
                }
            },
            link {
                ...,
                internalLink-> { _type, slug },
                fileLink {
                    asset-> { url }
                },
            },
        },
        file {
            ...,
            file {
                ...,
                asset->
            }
        },
    },
    seoSettings,
}`;

export async function getStaticProps({ params, locale }) {
	const { slug } = params;
	const results = await client.fetch(query, { slug: slug, lang: locale });

	if (!results) {
		return {
			notFound: true,
		};
	}

	return {
		props: { ...results },
		revalidate: 10,
	};
}
